import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import DOMPurify from 'dompurify';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { CSVLink } from 'react-csv';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import MobileOrientationAlert from './MobileOrientationAlert';


Modal.setAppElement('#root'); // Imposta l'elemento principale per l'accessibilità del modal

interface DropdownProps {
  value: string;
  onChange: (value: string) => void;
  className?: string;
}

interface CompetitorData1 {
  feature: string;
  area: string;
  importance: string;
  status: string;
}

interface CompetitorData2 {
  feature: string;
  yourSite: string;
  competitors: string[];
  yourSiteURL: string;
  competitorURLs: string[];
}

interface QueryData {
  pageType: string;
  queryIntent: string;
  queryPattern: string;
  queryExample: string;
  status?: string;
}

interface CompetitorComparisonTable1Props {
  data: CompetitorData1[];
  onStatusChange: (rowIndex: number, value: string) => void;
}

interface CompetitorComparisonTable2Props {
  data: CompetitorData2[];
  onUpdate: (rowIndex: number, colIndex: string | number, value: string) => void;
  competitorNames: string[];
  onEditURLs: (rowIndex: number) => void;
  onViewURLs: (rowIndex: number) => void;
}

interface QueryIntentTabProps {
  data: QueryData[];
  onUpdate: (rowIndex: number, field: string, value: string) => void;
  onAddRow: () => void;
  onStatusChange: (rowIndex: number, value: string) => void;
}

const sanitizeInput = (input: string) => {
  return DOMPurify.sanitize(input);
};

//dati iniziali
const initialCompetitorData1: CompetitorData1[] = [
  { feature: "The page should be crawlable, featuring a 200 OK HTTP status and not blocked through robots.txt", area: "Technical", importance: "Must Have", status: "-" },
  { feature: "The page should be loaded through a secured HTTPS URL and load only resources and images using HTTPS", area: "Technical", importance: "Must Have", status: "-" },
  { feature: "The page should be indexable to search engines and not feature a 'noindex' meta robots tag or X-Robots-Tag HTTP response header. Additionally, primary content upon user interaction shouldn't be lazy-loaded.", area: "Technical", importance: "Must Have", status: "-" },
  { feature: "The page should be featured through a single, descriptive, concise, and easy-to-read URL", area: "Technical", importance: "Important to Have", status: "-" },
  { feature: "The product page should be mobile-friendly, whether through a responsive web design, dynamic serving, or separate URLs, optimized for Google's Mobile First Index following Google's specifications", area: "Technical", importance: "Must Have", status: "-" },
  { feature: "The product page should feature breadcrumbs linking to its parent categories and site home page, facilitating site crawlability using breadcrumb structured data", area: "Technical", importance: "Important to Have", status: "-" },
  { feature: "The page should include relevant product structured data, including the review, aggregateRating, and offers properties if this type of content is available on the page, accurately reflecting its pricing, availability, and conditions.", area: "Technical", importance: "Important to Have", status: "-" },
  { feature: "The page should be included in your Google Merchant Center product feed", area: "Technical", importance: "Ideal to Have", status: "-" },
  { feature: "The page should feature an easy-to-use and crawlable navigation that links to the home page, main categories, parent category, or facet of the product. These links should be <a href> crawlable links, featuring descriptive anchor texts of the linked pages, and shouldn't rely on client-side JavaScript to be rendered but found in the raw HTML.", area: "Technical", importance: "Must Have", status: "-" },
  { feature: "The page should link to other similar products within the same, similar, or complementary site categories. These links should be <a href> crawlable links, featuring descriptive anchor texts of the linked product page, and shouldn't rely on client-side JavaScript to be rendered but found in the raw HTML.", area: "Technical", importance: "Must Have", status: "-" },
  { feature: "None of the key page configurations, navigation, or content areas should rely on client-side JavaScript to be rendered but should be found directly in the raw HTML to facilitate its indexability at scale.", area: "Technical", importance: "Important to Have", status: "-" },
  { feature: "Whenever indexable and with available products to sell, the page should be included in the relevant product XML sitemap.", area: "Technical", importance: "Important to Have", status: "-" },
  { feature: "The page should include a unique, descriptive, concise, relevant title tag of a maximum of 55 characters in total length.", area: "Content", importance: "Must Have", status: "-" },
  { feature: "The page should include a unique, descriptive, concise, relevant meta description tag that summarizes the product, with a maximum of 150 characters in total length.", area: "Content", importance: "Must Have", status: "-" },
  { feature: "The main product heading should be text-based, well visible on the page, including the product name, and featured with an H1 HTML tag.", area: "Content", importance: "Must Have", status: "-" },
  { feature: "The product page should feature original, high-quality, optimized-for-speed product photos or images.", area: "Content", importance: "Important to Have", status: "-" },
  { feature: "The product page should feature a high-quality and descriptive expert product video.", area: "Content", importance: "Ideal to Have", status: "-" },
  { feature: "The page should feature an original, comprehensive, grammatically correct, accurate, expertly written, text-based description and specification of the product.", area: "Content", importance: "Must Have", status: "-" },
  { feature: "The page should clearly feature and allow selection of the different available variants (sizes, colors, etc.) in a visible and easy-to-browse option.", area: "Content", importance: "Must Have", status: "-" },
  { feature: "The page should feature as many real product reviews and ratings from former customers as possible.", area: "Content", importance: "Important to Have", status: "-" },
  { feature: "The page should feature FAQs with answers by experts to the most common customer questions.", area: "Content", importance: "Ideal to Have", status: "-" },
  { feature: "The page should feature product questions and answers from former customers as possible.", area: "Content", importance: "Ideal to Have", status: "-" },
  { feature: "The page should feature a product comparison with similar ones.", area: "Content", importance: "Ideal to Have", status: "-" },
  { feature: "All the product page content should take into account E-E-A-T, and be helpful, high-quality, expert-driven, and people-first.", area: "Content", importance: "Must Have", status: "-" },
  { feature: "The product should clearly showcase a buy button, with a visible call to action and specify its conditions and policies accurately.", area: "Experience", importance: "Must Have", status: "-" },
  { feature: "The product page should showcase visible customer service details featuring contact information through different channels.", area: "Experience", importance: "Must Have", status: "-" },
  { feature: "The product page should provide commercially relevant trust signals for potential buyers.", area: "Experience", importance: "Must Have", status: "-" },
  { feature: "The product page should give a great user experience and have 'Good' Core Web Vitals.", area: "Experience", importance: "Important to Have", status: "-" },
];


const initialCompetitorData2: CompetitorData2[] = [
  { feature: "Main Navigation", yourSite: "-", competitors: ["-", "-", "-", "-", "-"], yourSiteURL: "", competitorURLs: ["", "", "", "", ""] },
  { feature: "Breadcrumbs", yourSite: "-", competitors: ["-", "-", "-", "-", "-"], yourSiteURL: "", competitorURLs: ["", "", "", "", ""] },
  { feature: "Product Images", yourSite: "-", competitors: ["-", "-", "-", "-", "-"], yourSiteURL: "", competitorURLs: ["", "", "", "", ""] },
  { feature: "Product Videos", yourSite: "-", competitors: ["-", "-", "-", "-", "-"], yourSiteURL: "", competitorURLs: ["", "", "", "", ""] },
  { feature: "Product Description", yourSite: "-", competitors: ["-", "-", "-", "-", "-"], yourSiteURL: "", competitorURLs: ["", "", "", "", ""] },
  { feature: "Product Ratings", yourSite: "-", competitors: ["-", "-", "-", "-", "-"], yourSiteURL: "", competitorURLs: ["", "", "", "", ""] },
  { feature: "Product Reviews", yourSite: "-", competitors: ["-", "-", "-", "-", "-"], yourSiteURL: "", competitorURLs: ["", "", "", "", ""] },
  { feature: "Option to see Product variations (sizes, colors, etc.)", yourSite: "-", competitors: ["-", "-", "-", "-", "-"], yourSiteURL: "", competitorURLs: ["", "", "", "", ""] },
  { feature: "Allow users to rate reviews 'Helpful' / 'Unhelpful'", yourSite: "-", competitors: ["-", "-", "-", "-", "-"], yourSiteURL: "", competitorURLs: ["", "", "", "", ""] },
  { feature: "Summary of Product Reviews Overall Best / Worst (AI Generated)", yourSite: "-", competitors: ["-", "-", "-", "-", "-"], yourSiteURL: "", competitorURLs: ["", "", "", "", ""] },
  { feature: "Product Q&As", yourSite: "-", competitors: ["-", "-", "-", "-", "-"], yourSiteURL: "", competitorURLs: ["", "", "", "", ""] },
  { feature: "Similar Product Comparison", yourSite: "-", competitors: ["-", "-", "-", "-", "-"], yourSiteURL: "", competitorURLs: ["", "", "", "", ""] },
  { feature: "Products Tips", yourSite: "-", competitors: ["-", "-", "-", "-", "-"], yourSiteURL: "", competitorURLs: ["", "", "", "", ""] },
  { feature: "\"You might also buy\" / Similar Product Recommendations", yourSite: "-", competitors: ["-", "-", "-", "-", "-"], yourSiteURL: "", competitorURLs: ["", "", "", "", ""] },
  { feature: "Customers \"also bought\" Recommendations", yourSite: "-", competitors: ["-", "-", "-", "-", "-"], yourSiteURL: "", competitorURLs: ["", "", "", "", ""] },
  { feature: "Related / Recommended Product Guides", yourSite: "-", competitors: ["-", "-", "-", "-", "-"], yourSiteURL: "", competitorURLs: ["", "", "", "", ""] },
  { feature: "\"Frequently bought together\" Recommendations", yourSite: "-", competitors: ["-", "-", "-", "-", "-"], yourSiteURL: "", competitorURLs: ["", "", "", "", ""] },
  { feature: "\"How to wear it\" / \"Shop this look\" Recommendations", yourSite: "-", competitors: ["-", "-", "-", "-", "-"], yourSiteURL: "", competitorURLs: ["", "", "", "", ""] },
  { feature: "Product \"Care\" Guides Recommendations", yourSite: "-", competitors: ["-", "-", "-", "-", "-"], yourSiteURL: "", competitorURLs: ["", "", "", "", ""] },
  { feature: "Customer images and video", yourSite: "-", competitors: ["-", "-", "-", "-", "-"], yourSiteURL: "", competitorURLs: ["", "", "", "", ""] },
  { feature: "Related items viewed", yourSite: "-", competitors: ["-", "-", "-", "-", "-"], yourSiteURL: "", competitorURLs: ["", "", "", "", ""] },
  { feature: "Average / Summary of Customer Ratings - Rate, Fit, etc.", yourSite: "-", competitors: ["-", "-", "-", "-", "-"], yourSiteURL: "", competitorURLs: ["", "", "", "", ""] },
];


const initialQueryData: QueryData[] = [
  { pageType: "Home Page", queryIntent: "Navigational", queryPattern: "site name", queryExample: "Walmart" },
  { pageType: "Home Page", queryIntent: "Navigational", queryPattern: "site name + online store", queryExample: "Walmart online store" },
  { pageType: "Home Page", queryIntent: "Informational", queryPattern: "online + store type", queryExample: "online retailer" },
  { pageType: "Products Category Pages", queryIntent: "Informational", queryPattern: "category name", queryExample: "headphones" },
  { pageType: "Products Category Pages", queryIntent: "Transactional", queryPattern: "buy + category name", queryExample: "buy headphones" },
  { pageType: "Products Category Pages", queryIntent: "Transactional", queryPattern: "buy + category name + online", queryExample: "buy headphones online" },
  { pageType: "Products Category Pages", queryIntent: "Navigational", queryPattern: "category name + site name", queryExample: "headphones Walmart" },
  { pageType: "Products Sub-Category Pages", queryIntent: "Informational", queryPattern: "sub-category name", queryExample: "wireless headphones" },
  { pageType: "Products Sub-Category Pages", queryIntent: "Transactional", queryPattern: "buy + sub-category name", queryExample: "buy wireless headphones" },
  { pageType: "Products Sub-Category Pages", queryIntent: "Transactional", queryPattern: "buy + sub-category name + online", queryExample: "buy wireless headphones online" },
  { pageType: "Products Sub-Category Pages", queryIntent: "Navigational", queryPattern: "sub-category name + site name", queryExample: "wireless headphones Walmart" },
  { pageType: "Products Category/Sub-category by Audience Pages", queryIntent: "Informational", queryPattern: "category name + audience name", queryExample: "women clothing" },
  { pageType: "Products Category/Sub-category by Audience Pages", queryIntent: "Transactional", queryPattern: "buy + category name + audience name", queryExample: "buy women clothing" },
  { pageType: "Products Category/Sub-category by Audience Pages", queryIntent: "Transactional", queryPattern: "buy + category name + audience name + online", queryExample: "buy women clothing online" },
  { pageType: "Products Category/Sub-category by Audience Pages", queryIntent: "Navigational", queryPattern: "category name + audience name + site name", queryExample: "women clothing walmart" },
  { pageType: "Products Facet/Filter/Feature Pages", queryIntent: "Informational", queryPattern: "facet name", queryExample: "wireless noise cancelling headphones" },
  { pageType: "Products Facet/Filter/Feature Pages", queryIntent: "Transactional", queryPattern: "buy + facet name", queryExample: "buy wireless noise cancelling headphones" },
  { pageType: "Products Facet/Filter/Feature Pages", queryIntent: "Transactional", queryPattern: "buy + facet name + online", queryExample: "buy wireless noise cancelling headphones online" },
  { pageType: "Products Facet/Filter/Feature Pages", queryIntent: "Navigational", queryPattern: "facet name + site name", queryExample: "wireless noise cancelling headphones Walmart" },
  { pageType: "Products Brand Pages", queryIntent: "Informational", queryPattern: "brand name + online", queryExample: "Bose Online" },
  { pageType: "Products Brand Pages", queryIntent: "Transactional", queryPattern: "buy + brand name", queryExample: "Buy Bose" },
  { pageType: "Products Brand Pages", queryIntent: "Transactional", queryPattern: "buy + brand name + online", queryExample: "Buy Bose Online" },
  { pageType: "Products Brand Pages", queryIntent: "Navigational", queryPattern: "brand name + site name", queryExample: "Bose Walmart" },
  { pageType: "Products Brand Category Pages", queryIntent: "Informational", queryPattern: "brand name + category name", queryExample: "Bose headphones" },
  { pageType: "Products Brand Category Pages", queryIntent: "Transactional", queryPattern: "buy + brand name + category name", queryExample: "Buy Bose headphones" },
  { pageType: "Products Brand Category Pages", queryIntent: "Transactional", queryPattern: "buy + brand name + category name + online", queryExample: "Buy Bose headphones online" },
  { pageType: "Products Brand Category Pages", queryIntent: "Navigational", queryPattern: "brand name + category name + site name", queryExample: "Bose headphones Walmart" },
  { pageType: "Products Models Comparison Pages", queryIntent: "Commercial", queryPattern: "compare + product name + model", queryExample: "Compare iPhone models" },
  { pageType: "Products Models Comparison Pages", queryIntent: "Commercial", queryPattern: "product name + model + comparison", queryExample: "iPhone models comparison" },
  { pageType: "Products Models Comparison Pages", queryIntent: "Navigational", queryPattern: "product name + models + comparison + site name", queryExample: "iPhone models comparison Walmart" },
  { pageType: "Products Detail Pages", queryIntent: "Informational", queryPattern: "product name", queryExample: "Bose QuietComfort 35 II Wireless Bluetooth Headphones, Noise-Cancelling, with Alexa Voice Control - Black" },
  { pageType: "Products Detail Pages", queryIntent: "Transactional", queryPattern: "buy + product name", queryExample: "Buy Bose QuietComfort 35 II Wireless Bluetooth Headphones, Noise-Cancelling, with Alexa Voice Control - Black" },
  { pageType: "Products Detail Pages", queryIntent: "Navigational", queryPattern: "buy + product name + site name", queryExample: "Buy Bose QuietComfort 35 II Wireless Bluetooth Headphones, Noise-Cancelling, with Alexa Voice Control - Black Amazon" },
  { pageType: "Products Reviews Pages", queryIntent: "Commercial", queryPattern: "product name + reviews", queryExample: "Bose QuietComfort 35 II Wireless Bluetooth Headphones, Noise-Cancelling, with Alexa Voice Control - Black Reviews" },
  { pageType: "Products Reviews Pages", queryIntent: "Navigational", queryPattern: "product name + reviews + site name", queryExample: "Bose QuietComfort 35 II Wireless Bluetooth Headphones, Noise-Cancelling, with Alexa Voice Control - Black Amazon Reviews" },
  { pageType: "Deals/Sale/Promotions Page", queryIntent: "Navigational", queryPattern: "site name + sale", queryExample: "Amazon sale" },
  { pageType: "Deals/Sale/Promotions Page", queryIntent: "Navigational", queryPattern: "site name + deals", queryExample: "Amazon deals" },
  { pageType: "Deals/Sale/Promotions Page", queryIntent: "Navigational", queryPattern: "site name + offers", queryExample: "Amazon offers" },
  { pageType: "Deals/Sale/Promotions Page", queryIntent: "Navigational", queryPattern: "site name + discounts", queryExample: "Amazon discounts" },
  { pageType: "Deals/Sale/Promotions Page", queryIntent: "Transactional", queryPattern: "store type + sale", queryExample: "online retailer sale" },
  { pageType: "Deals/Sale/Promotions Page", queryIntent: "Transactional", queryPattern: "store type + deals", queryExample: "online store deals" },
  { pageType: "Deals/Sale/Promotions Per Products Category Page", queryIntent: "Transactional", queryPattern: "category name + online sale", queryExample: "beauty online sale" },
  { pageType: "Deals/Sale/Promotions Per Products Category Page", queryIntent: "Transactional", queryPattern: "category name + online deals", queryExample: "beauty online deals" },
  { pageType: "Deals/Sale/Promotions Per Products Category Page", queryIntent: "Transactional", queryPattern: "category name + online offers", queryExample: "beauty online offers" },
  { pageType: "Deals/Sale/Promotions Per Products Category Page", queryIntent: "Transactional", queryPattern: "category name + online discounts", queryExample: "beauty online discounts" },
  { pageType: "Deals/Sale/Promotions Per Products Category Page", queryIntent: "Navigational", queryPattern: "category name + sale + site name", queryExample: "beauty sale amazon" },
  { pageType: "Deals/Sale/Promotions Per Ocassion Page", queryIntent: "Transactional", queryPattern: "promotion name + sale", queryExample: "Black Friday Sale" },
  { pageType: "Deals/Sale/Promotions Per Ocassion Page", queryIntent: "Transactional", queryPattern: "promotion name + deals", queryExample: "Black Friday Deals" },
  { pageType: "Deals/Sale/Promotions Per Ocassion Page", queryIntent: "Transactional", queryPattern: "promotion name + gift", queryExample: "Black Friday Gift" },
  { pageType: "Deals/Sale/Promotions Per Ocassion Page", queryIntent: "Navigational", queryPattern: "promotion name + site name", queryExample: "Black Friday Amazon" },
  { pageType: "Gift Cards Page", queryIntent: "Navigational", queryPattern: "gift card + site name", queryExample: "Gift Cards Amazon" },
  { pageType: "Gift Cards Page", queryIntent: "Navigational", queryPattern: "buy gift card + site name", queryExample: "Buy Gift Card Amazon" },
  { pageType: "Gift Cards Page", queryIntent: "Informational", queryPattern: "gift card + store type", queryExample: "online retailer gift card" },
  { pageType: "Gift Cards Page", queryIntent: "Transactional", queryPattern: "buy + gift card + store type", queryExample: "buy online retailer gift card" },
  { pageType: "Gift Cards Per Ocassion Page", queryIntent: "Informational", queryPattern: "ocassion + gift card", queryExample: "Mother's day Gift Card" },
  { pageType: "Gift Cards Per Ocassion Page", queryIntent: "Transactional", queryPattern: "buy + ocassion + gift card", queryExample: "Buy Mother's day Gift Card" },
  { pageType: "Gift Cards Per Ocassion Page", queryIntent: "Transactional", queryPattern: "buy + ocassion + gift card + online", queryExample: "Buy Mother's day Gift Card Online" },
  { pageType: "Gift Cards Per Ocassion Page", queryIntent: "Navigational", queryPattern: "site name + ocassion + gift card", queryExample: "Amazon Mother's day Gift Card" },
  { pageType: "Guides/Advice/Reviews for Products Category/Facet per Season Pages", queryIntent: "Commercial", queryPattern: "best + products category + season", queryExample: "best wireless routers 2019" },
  { pageType: "Guides/Advice/Reviews for Products Category/Facet per Season Pages", queryIntent: "Commercial", queryPattern: "products category + guide + season", queryExample: "wireless routers guide 2019" },
  { pageType: "Guides/Advice/Reviews for Products Category/Facet per Season Pages", queryIntent: "Commercial", queryPattern: "best + products category + review + season", queryExample: "best wireless routers review 2019" },
  { pageType: "Guides/Advice/Reviews for Products Category/Facet per Season Pages", queryIntent: "Commercial", queryPattern: "products category + season + tips", queryExample: "wireless routers 2019 tips" },
  { pageType: "Guides/Advice/Reviews for Products Category/Facet per Season Pages", queryIntent: "Commercial", queryPattern: "choose + products category + season", queryExample: "choose wireless routers 2019" },
  { pageType: "Guides/advice for Products related Topics/Questions Pages", queryIntent: "Informational", queryPattern: "how to start with + products topics", queryExample: "how to start with smart home automation" },
  { pageType: "Guides/advice for Products related Topics/Questions Pages", queryIntent: "Informational", queryPattern: "how to use + products topics", queryExample: "how to use smart home automation" },
  { pageType: "Guides/advice for Products related Topics/Questions Pages", queryIntent: "Informational", queryPattern: "products topics + guide", queryExample: "smart home automation guide" },
  { pageType: "Guides/advice for Products related Topics/Questions Pages", queryIntent: "Informational", queryPattern: "products topics + tips", queryExample: "smart home automation tips" },
  { pageType: "Help/Support/Contact Pages", queryIntent: "Navigational", queryPattern: "site name + support", queryExample: "Walmart support" },
  { pageType: "Help/Support/Contact Pages", queryIntent: "Navigational", queryPattern: "site name + online + support", queryExample: "Walmart online support" },
  { pageType: "Help/Support/Contact Pages", queryIntent: "Navigational", queryPattern: "site name + help", queryExample: "Walmart help" },
  { pageType: "Help/Support/Contact Pages", queryIntent: "Navigational", queryPattern: "site name + online + help", queryExample: "Walmart online help" },
  { pageType: "Help/Support/Contact Pages", queryIntent: "Navigational", queryPattern: "site name + online + contact", queryExample: "Walmart online contact" },
  { pageType: "Help/Support Topic/Question Pages", queryIntent: "Navigational", queryPattern: "site name + topic/question", queryExample: "Walmart Order delay" },
  { pageType: "Help/Support Topic/Question Pages", queryIntent: "Navigational", queryPattern: "site name + topic/question + support", queryExample: "Walmart order delay support" },
  { pageType: "Help/Support Topic/Question Pages", queryIntent: "Navigational", queryPattern: "site name + topic/question + help", queryExample: "Walmart order delay help" },
  { pageType: "About Page", queryIntent: "Navigational", queryPattern: "about + site name", queryExample: "About Walmart" },
  { pageType: "About Page", queryIntent: "Navigational", queryPattern: "site name + information", queryExample: "Walmart Information" },
  { pageType: "App Page", queryIntent: "Navigational", queryPattern: "site name + app", queryExample: "Walmart app" },
  { pageType: "App Page", queryIntent: "Transactional", queryPattern: "store type + app", queryExample: "retailer app" },
  { pageType: "App Page", queryIntent: "Transactional", queryPattern: "online + store type + app", queryExample: "online retailer app" },
  { pageType: "App Page", queryIntent: "Transactional", queryPattern: "store type + app + operating system", queryExample: "online retailer app ios" },
  { pageType: "Affiliates Page", queryIntent: "Navigational", queryPattern: "site name + affiliate program", queryExample: "Walmart affiliate program" },
  { pageType: "Affiliates Page", queryIntent: "Navigational", queryPattern: "become a + site name + affiliate", queryExample: "Become a Walmart affiliate" },
  { pageType: "Affiliates Page", queryIntent: "Navigational", queryPattern: "how to become a + site name + affiliate", queryExample: "how to become a Walmart affiliate" },
];

const Dropdown = ({ value, onChange, className, allowPartial = true }: DropdownProps & { allowPartial?: boolean }) => (
  <select
    value={value}
    onChange={(e) => onChange(e.target.value)}
    className={`w-full p-1 rounded ${
      value === 'Yes' ? 'bg-green-100 text-green-800' : 
      value === 'No' ? 'bg-red-100 text-red-800' : 
      value === 'Partial' ? 'bg-yellow-100 text-yellow-800' :
      'bg-gray-100'
    } ${className}`}
  >
    <option value="-">-</option>
    <option value="Yes">Yes</option>
    <option value="No">No</option>
    {allowPartial && <option value="Partial">Partial</option>}
  </select>
);

const CompetitorComparisonTable1 = ({ data, onStatusChange }: CompetitorComparisonTable1Props) => {
 const getImportanceColor = (importance: string) => {
  switch (importance) {
    case "Must Have":
      return "bg-green-100";
    case "Important to Have":
      return "bg-yellow-100";
    case "Ideal to Have":
      return "bg-red-100";
    default:
      return "bg-white";
  }
};

  const exportToPDF = () => {
  const doc = new jsPDF();
  autoTable(doc, {
    head: [['Feature', 'Area', 'Importance', 'Status']],
    body: data.map(row => [
      row.feature || '', 
      row.area || '', 
      row.importance || '', 
      row.status || ''
    ])
  });
  doc.save('CompetitorComparisonTable1.pdf');
};

  return (
    <div className="overflow-x-auto">
        <button onClick={exportToPDF} className="px-4 py-2 bg-blue-500 text-white rounded mb-4"> Export to PDF</button>
        <CSVLink data={data} filename={"CompetitorComparisonTable1.csv"} className="px-4 py-2 bg-green-500 text-white rounded mb-4 ml-2"> Export to CSV</CSVLink>
        <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="px-4 py-2">Feature</th>
            <th className="px-4 py-2">Area</th>
            <th className="px-4 py-2">Importance</th>
            <th className="px-4 py-2">Status</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              <td className="border px-4 py-2">{row.feature}</td>
              <td className="border px-4 py-2">{row.area}</td>
              <td className={`border px-4 py-2 ${getImportanceColor(row.importance)}`}>{row.importance}</td>
              <td className="border px-4 py-2 text-center">
                <Dropdown
                  value={row.status}
                  onChange={(value) => onStatusChange(rowIndex, value)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const CompetitorComparisonTable2 = ({ data, onUpdate, competitorNames, onEditURLs, onViewURLs }: CompetitorComparisonTable2Props) => {
  const exportToPDF = () => {
  const doc = new jsPDF();
  autoTable(doc, {
    head: [['Feature', 'Your Site', ...competitorNames]],
    body: data.map(row => [
      row.feature || '',
      row.yourSite || '',
      ...row.competitors.map(comp => comp || '')
    ])
  });
  doc.save('CompetitorComparisonTable2.pdf');
};


  return (
    <div className="overflow-x-auto">
      <button onClick={exportToPDF} className="px-4 py-2 bg-blue-500 text-white rounded mb-4">Export to PDF</button>
      <CSVLink data={data} filename={"CompetitorComparisonTable2.csv"} className="px-4 py-2 bg-green-500 text-white rounded mb-4 ml-2">Export to CSV</CSVLink>
      <table className="min-w-full bg-white border-collapse">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-4 py-2 border">Feature</th>
            <th className="px-4 py-2 border bg-indigo-100">Your Site</th>
            {competitorNames.map((name, index) => (
              <th key={index} className="px-4 py-2 border">{name || `Competitor ${index + 1}`}</th>
            ))}
            <th className="px-4 py-2 border">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex} className={rowIndex % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
              <td className="border px-4 py-2 font-medium">{row.feature}</td>
              <td className="border px-4 py-2">
                <Dropdown
                  value={row.yourSite}
                  onChange={(value) => onUpdate(rowIndex, 'yourSite', value)}
                  allowPartial={false}
                  className=""
                />
              </td>
              {row.competitors.map((value, colIndex) => (
                <td key={colIndex} className="border px-4 py-2">
                  <Dropdown
                    value={value}
                    onChange={(value) => onUpdate(rowIndex, colIndex, value)}
                    allowPartial={false}
                    className=""
                  />
                </td>
              ))}
              <td className="border px-4 py-2 text-center">
                <button onClick={() => onEditURLs(rowIndex)} className="mr-2">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828zM4 12.999H3v2.003A1.997 1.997 0 005 17h2.003v-1H4v-3.001z" />
                  </svg>
                </button>
                <button onClick={() => onViewURLs(rowIndex)}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M10 2C5.58 2 2 5.58 2 10s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zM10 4c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6 2.69-6 6-6zm0 1a.75.75 0 00-.75.75v1.75H7.5a.75.75 0 000 1.5h1.75v1.75a.75.75 0 001.5 0V10.75H12.5a.75.75 0 000-1.5H10.75V7.5a.75.75 0 00-.75-.75z" />
                  </svg>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const QueryIntentTab = ({ data, onUpdate, onAddRow, onStatusChange }: QueryIntentTabProps) => {
  const exportToPDF = () => {
  const doc = new jsPDF();
  autoTable(doc, {
    head: [['Page Type', 'Query Intent', 'Query Pattern', 'Query Example', 'Status']],
    body: data.map(row => [
      row.pageType || '', 
      row.queryIntent || '', 
      row.queryPattern || '', 
      row.queryExample || '', 
      row.status || ''
    ])
  });
  doc.save('QueryIntentTab.pdf');
};


  return (
    <div>
      <h2 className="text-xl font-semibold mb-4">SEO Query Intent Matching</h2>
      <button onClick={exportToPDF} className="px-4 py-2 bg-blue-500 text-white rounded mb-4">Export to PDF</button>
      <CSVLink data={data} filename={"QueryIntentTab.csv"} className="px-4 py-2 bg-green-500 text-white rounded mb-4 ml-2">Export to CSV</CSVLink>
      <div className="overflow-x-auto mb-4">
        <table className="min-w-full bg-white border-collapse">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-4 py-2 border">Page Type</th>
              <th className="px-4 py-2 border">Query Intent</th>
              <th className="px-4 py-2 border">Queries Pattern to Target</th>
              <th className="px-4 py-2 border">Query Example</th>
              <th className="px-4 py-2 border">Status</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                <td className="border px-4 py-2">
                  <input
                    type="text"
                    value={row.pageType}
                    onChange={(e) => onUpdate(index, 'pageType', sanitizeInput(e.target.value))}
                    className="w-full p-2 border rounded"
                  />
                </td>
                <td className="border px-4 py-2">
                  <select
                    value={row.queryIntent}
                    onChange={(e) => onUpdate(index, 'queryIntent', sanitizeInput(e.target.value))}
                    className="w-full p-2 border rounded"
                  >
                    <option value="-">-</option>
                    <option value="Navigational">Navigational</option>
                    <option value="Informational">Informational</option>
                    <option value="Transactional">Transactional</option>
                    <option value="Commercial">Commercial</option>
                  </select>
                </td>
                <td className="border px-4 py-2">
                  <input
                    type="text"
                    value={row.queryPattern}
                    onChange={(e) => onUpdate(index, 'queryPattern', sanitizeInput(e.target.value))}
                    className="w-full p-2 border rounded"
                  />
                </td>
                <td className="border px-4 py-2">
                  <input
                    type="text"
                    value={row.queryExample}
                    onChange={(e) => onUpdate(index, 'queryExample', sanitizeInput(e.target.value))}
                    className="w-full p-2 border rounded"
                  />
                </td>
                <td className="border px-4 py-2 text-center">
                  <Dropdown
                    value={row.status || '-'}
                    onChange={(value) => onStatusChange(index, value)}
                    className=""
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button
        onClick={onAddRow}
        className="px-4 py-2 bg-blue-500 text-white rounded"
      >
        Add New Row
      </button>
    </div>
  );
};




const UnifiedDashboard = () => {
  const [currentTab, setCurrentTab] = useState('dashboard1');
  const [competitorData1, setCompetitorData1] = useState<CompetitorData1[]>(() => {
    const savedData = localStorage.getItem('competitorData1');
    return savedData ? JSON.parse(savedData) : initialCompetitorData1;
  });
  const [competitorData2, setCompetitorData2] = useState<CompetitorData2[]>(() => {
    const savedData = localStorage.getItem('competitorData2');
    return savedData ? JSON.parse(savedData) : initialCompetitorData2;
  });
  const [queryData, setQueryData] = useState<QueryData[]>(() => {
    const savedData = localStorage.getItem('queryData');
    return savedData ? JSON.parse(savedData) : initialQueryData;
  });
  const [competitorNames, setCompetitorNames] = useState<string[]>(() => {
    const savedData = localStorage.getItem('competitorNames');
    return savedData ? JSON.parse(savedData) : ["Competitor 1", "Competitor 2", "Competitor 3", "Competitor 4", "Competitor 5"];
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [viewModalIsOpen, setViewModalIsOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState<number | null>(null);

  useEffect(() => {
    localStorage.setItem('competitorData1', JSON.stringify(competitorData1));
  }, [competitorData1]);

  useEffect(() => {
    localStorage.setItem('competitorData2', JSON.stringify(competitorData2));
  }, [competitorData2]);

  useEffect(() => {
    localStorage.setItem('queryData', JSON.stringify(queryData));
  }, [queryData]);

  useEffect(() => {
    localStorage.setItem('competitorNames', JSON.stringify(competitorNames));
  }, [competitorNames]);

  const handleStatusChange1 = (rowIndex: number, value: string) => {
    setCompetitorData1((prevData: CompetitorData1[]) => {
      const newData = [...prevData];
      newData[rowIndex] = { ...newData[rowIndex], status: value };
      return newData;
    });
  };

  const handleUpdate2 = (rowIndex: number, colIndex: string | number, value: string) => {
    setCompetitorData2((prevData: CompetitorData2[]) => {
      const newData = [...prevData];
      if (colIndex === 'yourSite') {
        newData[rowIndex] = { ...newData[rowIndex], yourSite: value };
      } else if (typeof colIndex === 'number') {
        const newCompetitors = [...newData[rowIndex].competitors];
        newCompetitors[colIndex] = value;
        newData[rowIndex] = { ...newData[rowIndex], competitors: newCompetitors };
      }
      return newData;
    });
  };

  const handleQueryUpdate = (rowIndex: number, field: string, value: string) => {
    setQueryData((prevData: QueryData[]) => {
      const newData = [...prevData];
      newData[rowIndex] = { ...newData[rowIndex], [field]: sanitizeInput(value) };
      return newData;
    });
  };

  const handleAddRow = () => {
    setQueryData((prevData: QueryData[]) => [
      ...prevData,
      { pageType: "", queryIntent: "-", queryPattern: "", queryExample: "" }
    ]);
  };

  const handleCompetitorNameChange = (index: number, value: string) => {
    setCompetitorNames((prev: string[]) => {
      const newNames = [...prev];
      newNames[index] = value;
      return newNames;
    });
  };

  const handleEditURLs = (rowIndex: number) => {
    setCurrentRow(rowIndex);
    setModalIsOpen(true);
  };

  const handleViewURLs = (rowIndex: number) => {
    setCurrentRow(rowIndex);
    setViewModalIsOpen(true);
  };

  const calculateCompletionPercentage1 = () => {
    const totalCells = competitorData1.length;
    const filledCells = competitorData1.reduce((acc: number, row: CompetitorData1) => {
      if (row.status === "Yes") {
        return acc + 1;
      } else if (row.status === "Partial") {
        return acc + 0.5;
      }
      return acc;
    }, 0);
    return (filledCells / totalCells) * 100;
  };

  const calculateCompletionPercentage2 = () => {
  const totalCells = competitorData2.length * (1 + competitorNames.length); // 1 for your site + number of competitors
  const filledCells = competitorData2.reduce((acc: number, row: CompetitorData2) => {
    const siteStatus = row.yourSite !== "-" ? 1 : 0;
    const competitorStatus = row.competitors.filter(value => value !== "-").length;
    return acc + siteStatus + competitorStatus;
  }, 0);
  return (filledCells / totalCells) * 100;
};


  const calculateCompletionPercentage3 = () => {
    const totalCells = queryData.length;
    const filledCells = queryData.reduce((acc: number, row: QueryData) => {
      if (row.status === "Yes") {
        return acc + 1;
      } else if (row.status === "Partial") {
        return acc + 0.5;
      }
      return acc;
    }, 0);
    return (filledCells / totalCells) * 100;
  };

  const resetSession = () => {
    localStorage.removeItem('competitorData1');
    localStorage.removeItem('competitorData2');
    localStorage.removeItem('queryData');
    localStorage.removeItem('competitorNames');
    setCompetitorData1(initialCompetitorData1);
    setCompetitorData2(initialCompetitorData2);
    setQueryData(initialQueryData);
    setCompetitorNames(["Competitor 1", "Competitor 2", "Competitor 3", "Competitor 4", "Competitor 5"]);
  };

  return (
    <>
    <MobileOrientationAlert />
    <div className="container mx-auto p-6 bg-gray-100">
     <h1 className="text-3xl font-bold mb-6 text-gray-800">E-commerce Dashboard</h1>
<p className="mb-6 text-gray-700">
  This dashboard is based on the work done by Aleyda Solis who created this <a href="https://docs.google.com/spreadsheets/d/1oKIB_dYyctaHv5FQ_EJWNZjIxs8CTjmnlmPqZsJXXMM/" 
     className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">spreadsheet</a>. 
  I, Alessandro Bellucci, have converted it into a dashboard using React and TypeScript. 
  This dashboard allows you to compare e-commerce product pages, track their SEO query intent matching, 
  and benchmark content against competitors. You can update and add data dynamically through the user interface.
  For any queries, feedback, or suggestions, feel free to contact me at <a href="https://x.com/seovlc" 
     className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">@seovlc</a>.
</p>
<p className="mb-6 text-gray-700">The data you enter in this dashboard is stored locally in your browser's session. <strong>This ensures that your data is secure and only accessible to you.</strong> If you wish to delete your data, please use the reset button provided.</p>

      <div className="bg-white p-4 rounded-lg shadow-md mb-6"> <button onClick={() => setCurrentTab('dashboard1')} 
          className={`px-4 py-2 mr-2 ${currentTab === 'dashboard1' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}>
          Ecommerce PDP Checklist
        </button>
        <button 
          onClick={() => setCurrentTab('dashboard2')} 
          className={`px-4 py-2 mr-2 ${currentTab === 'dashboard2' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
        >
          Ecommerce PDP Content Benchmark
        </button>
        <button 
          onClick={() => setCurrentTab('dashboard3')} 
          className={`px-4 py-2 ${currentTab === 'dashboard3' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
        >
          SEO Query Intent Matching
        </button>
      </div>
      <button 
        onClick={resetSession} 
        className="px-4 py-2 bg-red-500 text-white rounded mb-6"
      >
        Reset Session
      </button>
      {currentTab === 'dashboard1' && (
        <div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-6">
            <div className="flex justify-between items-center mb-2">
              <span className="text-lg font-semibold">Completion Progress</span>
              <span className="text-2xl font-bold">{Math.round(calculateCompletionPercentage1())}%</span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
              <div 
                className="bg-blue-600 h-2.5 rounded-full" 
                style={{ width: `${calculateCompletionPercentage1()}%` }}
              ></div>
            </div>
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md">
            <CompetitorComparisonTable1 data={competitorData1} onStatusChange={handleStatusChange1} />
          </div>
        </div>
      )}
      {currentTab === 'dashboard2' && (
        <div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-6">
            <h2 className="text-xl font-semibold mb-4">Competitor Names</h2>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
              {competitorNames.map((name, index) => (
                <div key={index}>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => handleCompetitorNameChange(index, sanitizeInput(e.target.value))}
                    className="w-full p-2 border rounded"
                    placeholder={`Competitor ${index + 1}`}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-6">
            <div className="flex justify-between items-center mb-2">
              <span className="text-lg font-semibold">Completion Progress</span>
              <span className="text-2xl font-bold">{Math.round(calculateCompletionPercentage2())}%</span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
              <div 
                className="bg-blue-600 h-2.5 rounded-full" 
                style={{ width: `${calculateCompletionPercentage2()}%` }}
              ></div>
            </div>
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md overflow-hidden">
            <CompetitorComparisonTable2 
              data={competitorData2} 
              onUpdate={handleUpdate2}
              competitorNames={competitorNames}
              onEditURLs={handleEditURLs}
              onViewURLs={handleViewURLs}
            />
          </div>
        </div>
      )}
      {currentTab === 'dashboard3' && (
        <div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-6">
            <div className="flex justify-between items-center mb-2">
              <span className="text-lg font-semibold">Completion Progress</span>
              <span className="text-2xl font-bold">{Math.round(calculateCompletionPercentage3())}%</span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
              <div 
                className="bg-blue-600 h-2.5 rounded-full" 
                style={{ width: `${calculateCompletionPercentage3()}%` }}
              ></div>
            </div>
          </div>
          <QueryIntentTab data={queryData} onUpdate={handleQueryUpdate} onAddRow={handleAddRow} onStatusChange={(rowIndex, value) => {
            setQueryData((prevData: QueryData[]) => {
              const newData = [...prevData];
              newData[rowIndex] = { ...newData[rowIndex], status: value };
              return newData;
            });
          }} />
        </div>
      )}
{modalIsOpen && currentRow !== null && (
  <Modal
    isOpen={modalIsOpen}
    onRequestClose={() => setModalIsOpen(false)}
    contentLabel="Edit URLs"
    className="bg-white rounded-lg p-6 w-1/2 mx-auto mt-20"
  >
    <div className="flex justify-between items-center mb-4">
      <h2 className="text-xl font-semibold">Edit URLs for {DOMPurify.sanitize(competitorData2[currentRow].feature)}</h2>
      <button onClick={() => setModalIsOpen(false)} className="text-gray-500 hover:text-gray-700">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    </div>
    <div className="mb-4">
      <label className="block mb-1 font-medium">Your Site URL</label>
      <input
        type="text"
        value={competitorData2[currentRow].yourSiteURL}
        onChange={(e) => {
          const newData = [...competitorData2];
          newData[currentRow].yourSiteURL = DOMPurify.sanitize(e.target.value);
          setCompetitorData2(newData);
        }}
        className="w-full p-2 border rounded"
        placeholder="Enter Your Site URL"
      />
    </div>
    {competitorNames.map((name, index) => (
      <div className="mb-4" key={index}>
        <label className="block mb-1 font-medium">{DOMPurify.sanitize(name)} URL</label>
        <input
          type="text"
          value={competitorData2[currentRow].competitorURLs[index]}
          onChange={(e) => {
            const newData = [...competitorData2];
            newData[currentRow].competitorURLs[index] = DOMPurify.sanitize(e.target.value);
            setCompetitorData2(newData);
          }}
          className="w-full p-2 border rounded"
          placeholder={`Enter ${DOMPurify.sanitize(name)} URL`}
        />
      </div>
    ))}
    <button
      onClick={() => setModalIsOpen(false)}
      className="px-4 py-2 bg-blue-500 text-white rounded"
    >
      Save
    </button>
  </Modal>
)}

{viewModalIsOpen && currentRow !== null && (
  <Modal
    isOpen={viewModalIsOpen}
    onRequestClose={() => setViewModalIsOpen(false)}
    contentLabel="View URLs"
    className="bg-white rounded-lg p-6 w-1/2 mx-auto mt-20"
  >
    <div className="flex justify-between items-center mb-4">
      <h2 className="text-xl font-semibold">URLs for {DOMPurify.sanitize(competitorData2[currentRow].feature)}</h2>
      <button onClick={() => setViewModalIsOpen(false)} className="text-gray-500 hover:text-gray-700">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    </div>
    <div className="mb-4">
      <label className="block mb-1 font-medium">Your Site URL</label>
      <p className="w-full p-2 border rounded">{DOMPurify.sanitize(competitorData2[currentRow].yourSiteURL)}</p>
    </div>
    {competitorNames.map((name, index) => (
      <div className="mb-4" key={index}>
        <label className="block mb-1 font-medium">{DOMPurify.sanitize(name)} URL</label>
        <p className="w-full p-2 border rounded">{DOMPurify.sanitize(competitorData2[currentRow].competitorURLs[index])}</p>
      </div>
    ))}
    <button
      onClick={() => setViewModalIsOpen(false)}
      className="px-4 py-2 bg-blue-500 text-white rounded"
    >
      Close
    </button>
  </Modal>
)}
    </div>
    </>
  );
};

export default UnifiedDashboard;
