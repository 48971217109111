import React, { useState, useEffect } from 'react';

const MobileOrientationAlert: React.FC = () => {
  const [showAlert, setShowAlert] = useState(false);

  const checkOrientation = () => {
    if (window.innerWidth < 768) {
      setShowAlert(window.innerHeight > window.innerWidth);
    } else {
      setShowAlert(false);
    }
  };

  useEffect(() => {
    checkOrientation();
    window.addEventListener('resize', checkOrientation);
    window.addEventListener('orientationchange', checkOrientation);

    return () => {
      window.removeEventListener('resize', checkOrientation);
      window.removeEventListener('orientationchange', checkOrientation);
    };
  }, []);

  if (!showAlert) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-yellow-100 p-4 text-center text-yellow-800 z-50">
      For optimal viewing on mobile devices, please rotate your device to landscape mode.
    </div>
  );
};

export default MobileOrientationAlert;
